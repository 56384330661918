const path = require("path");

module.exports = {
  publicPath: "/billing",
  outputDir: "dist/billing",
  assetsDir: "assets",
  lintOnSave: process.env.NODE_ENV !== "production",
  pages: {
    index: {
      // entry for the page
      entry: "src/main.js",
      // the source template
      template: "public/index.html",
      // output as dist/index.html
      filename: "index.html",
      // when using title option,
      // template title tag needs to be <title><%= htmlWebpackPlugin.options.title %></title>
      title: "Index Page",
      // chunks to include on this page, by default includes
      // extracted common chunks and vendor chunks.
      // chunks: ["chunk-vendors", "chunk-common", "index"],
    },
    header: {
      // entry for the page
      entry: "src/header.js",
      // the source template
      template: "public/assets/header.html",
      // output as dist/index.html
      filename: "assets/header.html",
      // when using title option,
      // template title tag needs to be <title><%= htmlWebpackPlugin.options.title %></title>
      title: "Header Page",
      outputDir: "header",
      // chunks to include on this page, by default includes
      // extracted common chunks and vendor chunks.
      chunks: ["chunk-vendors", "chunk-common", "header"],
    },
    sidebar: {
      // entry for the page
      entry: "src/sidebar.js",
      // the source template
      template: "public/assets/sidebar.html",
      // output as dist/index.html
      filename: "assets/sidebar.html",
      // when using title option,
      // template title tag needs to be <title><%= htmlWebpackPlugin.options.title %></title>
      title: "Sidebar Page",
      outputDir: "sidebar",
      // chunks to include on this page, by default includes
      // extracted common chunks and vendor chunks.
      chunks: ["chunk-vendors", "chunk-common", "sidebar"],
    },
    footer: {
      // entry for the page
      entry: "src/footer.js",
      // the source template
      template: "public/assets/footer.html",
      // output as dist/index.html
      filename: "assets/footer.html",
      // when using title option,
      // template title tag needs to be <title><%= htmlWebpackPlugin.options.title %></title>
      title: "Footer Page",
      outputDir: "footer",
      // chunks to include on this page, by default includes
      // extracted common chunks and vendor chunks.
      chunks: ["chunk-vendors", "chunk-common", "footer"],
    },
  },
  configureWebpack: {
    resolve: {
      alias: {
        "@": path.join(__dirname, "src"),
        "~": path.join(__dirname, "node_modules"),
      },
      fallback: {
        path: require.resolve("path-browserify"),
      },
    },
    devServer: {
      // CORS is safe as it's only used for local development server.
      headers: { "Access-Control-Allow-Origin": "*" },
    },
    output: {
      library: `common-internet-name`,
      libraryTarget: "umd",
      chunkLoadingGlobal: `webpackJsonp_common-internet`,
    },
  },
  chainWebpack: (config) => {
    config.plugin("copy").tap((args) => {
      args[0].patterns.push({
        from: path.resolve(
          __dirname,
          "node_modules/@xtremax/mas-ba-frameworks/pages",
        ),
        to: path.resolve(__dirname, "dist"),
        toType: "dir",
      });
      return args;
    });
  },
};
